import React, { useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import { useDispatch,  } from "react-redux";
import { Actions } from "../../store";

export const AutoSavingPopup = ({autosaving=false}) => {

    const Dispatch = useDispatch()

    useEffect(()=>{
        
        if(autosaving) setTimeout(()=>{  Dispatch(Actions.setAutoSavingAction(false)) },2100)
    },[autosaving])

    return (
        <div className={`absolute transform duration-700 ${ autosaving ? "top-2" : "-top-20" }  left-[45%] mx-auto bg-white p-3 rounded-8px shadow-md flex space-x-2 border-1`} >
            <CgSpinner className="w-5 my-auto h-5 animate-spin text-green-700" />   
            <p className="flex my-auto text-sm" >{"auto saving..."}</p>

        </div>
    )
}