import React, { useEffect, useState } from "react";
import { Imageinput } from "../../../input/imageinput";
import InputWithLabel from "../../../input/inputWithLabel";
import { PairCardBlock } from "../../../paircard";
import DropMenu from "../../../input/dropmenu";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { MediaPopup } from "../../../popup/Mediapopup";
import { ColorPixerInput } from "../../../input/colorpixerInput";

export const FindpairSettings = ({ field, fieldId }) => {

    const Dispatch = useDispatch();
     
    let listOfObj = {};

    let keyArray = field?.pairItems?.map((list) => {

        if (!listOfObj[list.pairValue]) listOfObj[list.pairValue] = [list];

        else listOfObj[list.pairValue] = [...listOfObj[list.pairValue], list]

    })

    var dropmenuList = ['4x3','4x4','5x2','5x4','6x5','6x4'];
    if(field?.pairType==="match")  dropmenuList = ['4','5','6','7'];
    let color=(field?.filpCardBgColor || "#DCE0E4");
    const [btnTextColor,setBtnTextColor] = useState(color);

    const findIndex = dropmenuList.findIndex( list => list === field?.layputRange);

    const [pairValue, setpairValue] = useState('');
    const [selectcard, setselectcard] = useState(0);

    const ContentChange = (e,type,Checkstate = false) => {
        let stateCheck = false;

        const IsCheckBox = e.target.type === 'checkbox' ? true : false ;

        if (IsCheckBox) stateCheck =  !Checkstate ? true : false

        const SetValue = IsCheckBox ? stateCheck : e.target.value

        const setContent = {
            isCommonSetting:false,
            key:fieldId,
            node:'game_mainpage_json',
            child:type,
            value:SetValue
        }
 
        Dispatch(Actions.setFieldTemplateContentChange(setContent))

    }

    const DirectContentChange = (value,type) => {         

        const setContent = {
            isCommonSetting:false,
            key:fieldId,
            node:'game_mainpage_json',
            child:type,
            value:value
        }
        if(field?.pairType==="match") {
            Dispatch(Actions.setMatchTemplatePairChange(setContent))

        }else{
            Dispatch(Actions.setFieldTemplateContentChange(setContent))
            Dispatch(Actions.setFieldTemplatePairChange(setContent))
        }
        if(ShowMedia) setShowMidia(false)
        
        if(type==="filpCardBgColor") setBtnTextColor(value)
    }

    const [ShowMedia,setShowMidia] = useState(false);
    const [ShowMediapair,setShowMidiapair] = useState(false);
    const TriggerMedia = (pairval,index,value,type) => {
        if (type) {
            setShowMidiapair(true)
            setpairValue(pairval)
            setselectcard(index)
        }
    }

    const setValuecontent = (value='',type = 'image',indexcard=selectcard,pair=pairValue) => {
        const setContent = {
            isCommonSetting:false,
            key:fieldId,
            node:'game_mainpage_json',
            child:'pairItems',
            type:type,
            pairIndex:indexcard,
            pairvalue:pair,
            value:value
        }

        Dispatch(Actions.setpairContentChange(setContent))
        setpairValue('')
        setselectcard(0);
        setShowMidia(false)
    }

    return (
        <div className="w-full space-y-4 ">

            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'filpCardBgImage'} OnSelect={DirectContentChange} />}
            
            {ShowMediapair && <MediaPopup cancel={setShowMidiapair} fieldType={'image'} OnSelect={setValuecontent} />}

            <DropMenu label={'choose layout :'} list={dropmenuList} active = {findIndex < 0 ? 0 : findIndex} fieldType={'layputRange'} onchange={DirectContentChange} />

            {field?.pairType!=="match" && <Imageinput label="card Background" src={field?.filpCardBgImage} chanaeAction={()=>setShowMidia(true)} showDel={false} />}
           
            {field?.pairType!=="match" && <ColorPixerInput labelTextsize="font-medium"  label='Card Color'  mainclass="w-full" fieldType={"filpCardBgColor"} colorHex={btnTextColor}  Onchange={DirectContentChange}/> }

            <div className="w-full space-y-4" >

                <PairCardBlock pairItems={Object.values(listOfObj)} setValuecontent={setValuecontent} fieldId={fieldId} TriggerMedia={TriggerMedia} pairValue={pairValue} setselectcard={setselectcard} setpairValue={setpairValue} selectcard={selectcard} />

            </div>

        </div>
    )
}