import React, { useEffect, useState } from "react";
import { CalculatorBlock } from "../../../calculator";
import PrimaryButton from "../../../Button1/primarybutton";
import { Actions } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";


export const Calculator = ({ field, fieldId }) => {

    const Dispath = useDispatch()
    const [activeQue,setQctiveQue] = useState(0)

    const [arrAction, setarrAction] = useState([])

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldId];
    const Settings = templateData?.filedContent
    const resultScreenContent = Settings.game_resultcreen_json;
    const AddQues = () => {
        const setContent = {
            key:fieldId,
            node:'game_mainpage_json',
            child:'questionsList',
            value:{
                "question": "1. Test header........",
                "InPutType": "number",
                "IsOPtionType": false,
                "questionId": Math.random().toString(16).slice(8) + Date.now().toString(36),
                "options": [
                  {
                    "key": "Local",
                    "value": 50
                  },
                  {
                    "key": "Local",
                    "value": 100
                  }
                ]
              }
        }
        Dispath(Actions.setQuizQuestionAdd(setContent))
    }

    const addQuestionId = ( childInd ) => {

      let payload = {

        key:fieldId,
        node:'game_mainpage_json',
        child:'questionsList',
        childInd

      }

      Dispath(Actions.questionIdAdd( payload ))

    }

    useEffect (() => {
    let arr  = []
      field?.questionsList?.map( (list,index) => arr.push(list?.question) )
      if("results" in resultScreenContent) resultScreenContent?.results.map( (list,index) => arr.push(list?.titleContent) )
      else arr.push(resultScreenContent?.titleContent)
      setarrAction(arr)
    },[field]) 

    return (

        <div className="w-full space-y-4 ">
            {
                field?.questionsList?.map((list,index) => {

                    if(!list?.questionId) addQuestionId( index )

                   return (<CalculatorBlock Index={index} required={list?.required ?? false} setQctiveQue={setQctiveQue} isAcitve={activeQue} jumpToLeft = {arrAction} rules={list?.rules} InPutType={list?.InPutType} question={list?.question} IsOPtionType={list?.IsOPtionType} fieldId={fieldId} options={list?.options} />)
                
                  })
            }
            <PrimaryButton text={'Add Question'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddQues}  />

        </div>
    )
}