import React  from "react";
export const opertatorData = () => {
    return [
        {
            type:"Add",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2755_15523)">
            <path d="M18.5 13H13.5V18C13.5 18.55 13.05 19 12.5 19C11.95 19 11.5 18.55 11.5 18V13H6.5C5.95 13 5.5 12.55 5.5 12C5.5 11.45 5.95 11 6.5 11H11.5V6C11.5 5.45 11.95 5 12.5 5C13.05 5 13.5 5.45 13.5 6V11H18.5C19.05 11 19.5 11.45 19.5 12C19.5 12.55 19.05 13 18.5 13Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2755_15523">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Subtract",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2755_15526)">
            <path d="M18.5 13H6.5C5.95 13 5.5 12.55 5.5 12C5.5 11.45 5.95 11 6.5 11H18.5C19.05 11 19.5 11.45 19.5 12C19.5 12.55 19.05 13 18.5 13Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2755_15526">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Multiply",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2755_15529)">
            <path d="M18.8 5.70973C18.41 5.31973 17.78 5.31973 17.39 5.70973L12.5 10.5897L7.60997 5.69973C7.21997 5.30973 6.58997 5.30973 6.19997 5.69973C5.80997 6.08973 5.80997 6.71973 6.19997 7.10973L11.09 11.9997L6.19997 16.8897C5.80997 17.2797 5.80997 17.9097 6.19997 18.2997C6.58997 18.6897 7.21997 18.6897 7.60997 18.2997L12.5 13.4097L17.39 18.2997C17.78 18.6897 18.41 18.6897 18.8 18.2997C19.19 17.9097 19.19 17.2797 18.8 16.8897L13.91 11.9997L18.8 7.10973C19.18 6.72973 19.18 6.08973 18.8 5.70973Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2755_15529">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Divide",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2755_15532)">
            <path d="M18.5 13H6.5C5.95 13 5.5 12.55 5.5 12C5.5 11.45 5.95 11 6.5 11H18.5C19.05 11 19.5 11.45 19.5 12C19.5 12.55 19.05 13 18.5 13Z" fill="#06152D"/>
            <path d="M12.4992 15.2002C13.4919 15.2002 14.2992 16.0075 14.2992 17.0001C14.2992 17.9928 13.4918 18.8 12.4992 18.8C11.5066 18.8 10.6992 17.9924 10.6992 17.0001C10.6992 16.0075 11.5069 15.2002 12.4992 15.2002Z" fill="#06152D"/>
            <path d="M12.4992 5.2002C13.4919 5.2002 14.2992 6.00755 14.2992 7.0001C14.2992 7.99285 13.4918 8.8 12.4992 8.8C11.5066 8.8 10.6992 7.99245 10.6992 7.0001C10.6992 6.00755 11.5069 5.2002 12.4992 5.2002Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2755_15532">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Sum",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2773_15613)">
            <path d="M17 4H8.06C7.2 4 6.5 4.7 6.5 5.56C6.5 5.84 6.62 6.11 6.82 6.3L13 12L6.82 17.7C6.62 17.89 6.5 18.16 6.5 18.44C6.5 19.3 7.2 20 8.06 20H17C17.83 20 18.5 19.33 18.5 18.5C18.5 17.67 17.83 17 17 17H11.5L15.09 13.41C15.87 12.63 15.87 11.36 15.09 10.58L11.5 7H17C17.83 7 18.5 6.33 18.5 5.5C18.5 4.67 17.83 4 17 4Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2773_15613">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Average",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2773_15616)">
            <path d="M7 21H3.5C2.95 21 2.5 20.55 2.5 20V10C2.5 9.45 2.95 9 3.5 9H7C7.55 9 8 9.45 8 10V20C8 20.55 7.55 21 7 21ZM14.25 3H10.75C10.2 3 9.75 3.45 9.75 4V20C9.75 20.55 10.2 21 10.75 21H14.25C14.8 21 15.25 20.55 15.25 20V4C15.25 3.45 14.8 3 14.25 3ZM21.5 11H18C17.45 11 17 11.45 17 12V20C17 20.55 17.45 21 18 21H21.5C22.05 21 22.5 20.55 22.5 20V12C22.5 11.45 22.05 11 21.5 11Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2773_15616">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Min",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2773_15620)">
            <path d="M20.5007 6.66667V9.33333C20.5007 10.4333 19.6007 11.3333 18.5007 11.3333H15.834C14.734 11.3333 13.834 10.4333 13.834 9.33333V6.66667C13.834 5.56667 14.734 4.66667 15.834 4.66667H18.5007C19.6007 4.66667 20.5007 5.56667 20.5007 6.66667ZM18.5007 14H17.1673C16.0673 14 15.1673 14.9 15.1673 16V17.3333C15.1673 18.4333 16.0673 19.3333 17.1673 19.3333H18.5007C19.6007 19.3333 20.5007 18.4333 20.5007 17.3333V16C20.5007 14.9 19.6007 14 18.5007 14ZM11.374 16.1867L9.16732 18.2867V4.66667C9.16732 4.3 8.86732 4 8.50066 4C8.13399 4 7.83399 4.3 7.83399 4.66667V18.2867L5.62733 16.1867C5.36066 15.9333 4.94066 15.94 4.68733 16.2067C4.43399 16.4733 4.44066 16.8933 4.70733 17.1467L7.08732 19.4133C7.46732 19.7933 7.96732 20 8.50066 20C9.03399 20 9.53399 19.7933 9.90066 19.4267L12.294 17.1533C12.5607 16.9 12.574 16.48 12.314 16.2133C12.0607 15.9467 11.6407 15.9333 11.374 16.1933V16.1867Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2773_15620">
            <rect width="16" height="16" fill="white" transform="translate(4.5 4)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
        {
            type:"Max",
            icon:(<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2773_15623)">
            <path d="M20.499 16V17.3333C20.499 18.4333 19.599 19.3333 18.499 19.3333H17.1657C16.0657 19.3333 15.1657 18.4333 15.1657 17.3333V16C15.1657 14.9 16.0657 14 17.1657 14H18.499C19.599 14 20.499 14.9 20.499 16ZM18.499 4.66668H15.8324C14.7324 4.66668 13.8324 5.56668 13.8324 6.66668V9.33335C13.8324 10.4333 14.7324 11.3333 15.8324 11.3333H18.499C19.599 11.3333 20.499 10.4333 20.499 9.33335V6.66668C20.499 5.56668 19.599 4.66668 18.499 4.66668ZM9.91236 4.58668C9.13236 3.80668 7.86569 3.80668 7.09903 4.57334L4.70569 6.85335C4.43903 7.10668 4.42569 7.52668 4.67903 7.79335C4.93236 8.06001 5.35236 8.07335 5.61903 7.81335L7.82569 5.71334V19.3333C7.82569 19.7 8.12569 20 8.49236 20C8.85903 20 9.15903 19.7 9.15903 19.3333V5.71334L11.3657 7.81335C11.4924 7.93335 11.659 8.00001 11.8257 8.00001C11.999 8.00001 12.179 7.93335 12.3057 7.79335C12.559 7.52668 12.5524 7.10668 12.279 6.85335L9.91236 4.58668Z" fill="#06152D"/>
            </g>
            <defs>
            <clipPath id="clip0_2773_15623">
            <rect width="16" height="16" fill="white" transform="translate(4.5 4)"/>
            </clipPath>
            </defs>
            </svg>
            )
        },
    ]
} 