import React, { useState } from "react";
import { useEffect } from "react";


export const Rangeinput = ({
    mainBlockclass="",
    minValue=0,
    maxValue=100,
    defaultValue=0,
    mainWidth='w-full',
    OnChange=null,
    primaryColor='rgb(167, 104, 006)',
    secondaryColor='rgb(167, 104, 006)',
    showHoverStyle="",
    showBottomNums=""

}) => {

    const [thumbvalue,setthumbval] = useState(defaultValue)
    const [hoverValpos,sethoverValpos] = useState(defaultValue)
    const [hoverValwidth,sethoverValwidth] = useState(1)

    const OnchangeVal = (e) =>{
        let Elem = document.getElementById('CustomCalRange').clientWidth
        let ElemhoverValpos = document.getElementById('hoverValpos').clientWidth
        let contRangePer =  (e.target.value/maxValue).toFixed(2)
        sethoverValpos((Elem*contRangePer).toFixed(2))
        sethoverValwidth(ElemhoverValpos)
        setthumbval(e.target.value)
        OnChange(e)
    }

    useEffect(()=>{
        document.getElementById("CustomCalRange").style.setProperty('--InputRangecolor',primaryColor)
    },[])
    return (
        <div className={`${mainBlockclass} ${mainWidth} flex flex-col space-y-2 relative`} >

            <p className={`relative font-medium w-fit flex text-center bg-white rounded-md py-1 px-2 -top-5 ${showHoverStyle}`}  id="hoverValpos" style={{background:primaryColor,color:secondaryColor,left:`${hoverValpos-(hoverValwidth/2)}px`}} >{thumbvalue}</p>

            <input type="range" className="w-full " id="CustomCalRange" max={100} min={0} defaultValue={defaultValue} onChange={OnchangeVal} />

            <div className={`w-full relative flex justify-between ${showBottomNums}`}>
                <p className="w-20 text-center m inline-block absolute -left-10 py-2"  style={{color:primaryColor}} >{minValue}</p>
                <p className="w-20 text-center font-medium inline-block absolute -right-10 py-2"  style={{color:primaryColor}}>{maxValue}</p>
            </div>

        </div>
    )
}