import React, { useState } from "react";
import InputWithLabel from "../input/inputWithLabel";
import DefaultDropMenu from "../input/droplist";
import PrimaryButton from "../Button1/primarybutton";
import CheckBox from "../input/checkbox";
import TextareaInput from "../input/textarea";

export const InputPopup = ({
  className = "",
  children,
  useChildren = false,
  close = null,
  setValue = null,
  setCheckBoxRedirUrl=null,
  setSelect = null,
  InputType = [],
  updateData = null,
  filedRequired=false,
  setRequired=null,
  showList = [],
  curVal="",
  setTextAreaVal=null
}) => {


  const [selectedVal, setselectedVal]= useState("")
  function setSelectLocal(index){
    setSelect(index)
    setselectedVal(InputType[index])
  }

  const activeSateInd = InputType.findIndex(resData => resData === String(selectedVal).toLocaleLowerCase())
  return (
    <div
      className={`fixed flex bg-black bg-opacity-5 w-full m-0 p-0 h-full shadow-lg top-0 left-0  justify-center items-center    ${className}`}
      style={{ zIndex: "170" }}
    >
      {useChildren ? (
        { children }
      ) : (
        <div className="max-w-[750px] w-3/5 bg-white  h-fit py-4 pb-4 space-y-4  rounded-xl ">
          <div className="flex  justify-between   w-[80%] mx-auto ">
            <div className="text-lg font-medium">Add Input Filed</div>
            <div>
              <span
                className="cursor-pointer bg-white"
                onClick={() => close(false)}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.825 6.18562C19.4025 5.76312 18.72 5.76312 18.2975 6.18562L13 11.4723L7.70253 6.17479C7.28003 5.75229 6.59753 5.75229 6.17503 6.17479C5.75253 6.59729 5.75253 7.27979 6.17503 7.70229L11.4725 12.9998L6.17503 18.2973C5.75253 18.7198 5.75253 19.4023 6.17503 19.8248C6.59753 20.2473 7.28003 20.2473 7.70253 19.8248L13 14.5273L18.2975 19.8248C18.72 20.2473 19.4025 20.2473 19.825 19.8248C20.2475 19.4023 20.2475 18.7198 19.825 18.2973L14.5275 12.9998L19.825 7.70229C20.2367 7.29062 20.2367 6.59729 19.825 6.18562Z"
                    fill="#586474"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="flex  space-y-4   w-[80%] mx-auto flex-col">
            <div className="">
              <InputWithLabel
                label="Lable name"
                onchange={(e) => setValue(e.target.value)} 
              />
            </div>
            <div className="">
              <DefaultDropMenu label="Input type" margin="mb-0 caret-transparent"
                list={InputType}
                SelectIndex={true} 
                onchange={(index) => setSelectLocal(index)}
                active={activeSateInd < 0 ? 0 : activeSateInd}  />
            </div>
{ curVal==="select" &&
            <div className="">
         <TextareaInput label={"Options (use comma for each option)"} onchange={(e) => setTextAreaVal(e.target.value)}   />

            </div>
}

{ curVal==="checkbox" &&
            <div className="">
        <InputWithLabel
                label="Redirect URL" 
                Placeholder="https://gozen.io"
                onchange={(e) => setCheckBoxRedirUrl(e.target.value)} 
              />
            </div>
}
            <CheckBox  label={'Required'} labelTextsize={'text-sm'} isChecked={filedRequired} onchange={(e) => setRequired(!filedRequired) } /> 
            <div className=" w-full h-[50px] ">
              <PrimaryButton
                text={"Add"}
                OnClick={() => updateData()}
                classNames="min-w-full min-h-full"
              />
            </div>
          </div>


        </div>
      )}
    </div>
  );
};
