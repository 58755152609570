import React, { useState } from "react";
import { Description, Title } from "../Texts";
import { ImageCenter } from "../Image/ImageCenter";
import InputWithLabel from "../input/inputWithLabel";
import { IntegrationlistEditSec } from "./IntegrationListEdit";
import { LoadingSpinner } from "../Loading";
import { listOfIcon } from "../../content/iconList";

export const ValidateIntegration = ({

    select = {},
    ActiveSec = 0,
    Loading=false,
    setCredentials = null ,
    Workspace = [],
    ListObj=[],
    TagObj=[]

}) => {

    let credentials = { };

    const ApikeyContentChange = ( type ,val ) => {

        credentials[type] = val;

        setCredentials(credentials)

    }

    const doubleArrowIcon = listOfIcon('doubleArrow',27,19,'#8A929E')

    return (
        <div className=" w-full h-full flex bg-white m-auto space-y-4 overflow-hidden">

            {/*  Left Section or Banner section */}
            <div className="w-[30%] h-full bg-[#F9FAFB] flex flex-col  pt-8 mx-atuo">
                <div className="w-full space-y-4">

                    <Title className="capitalize font-bold text-center text-2xl" text={`${select["mailer"].split('_').join(" ")}  Integration setup`} />

                    <div className="w-[90%] mx-auto space-y-4">

                        <div className=" flex justify-between w-4/5 mx-auto ">

                            <ImageCenter src="/asset/images/log/logo.png" className="w-[40px] h-[40px]" />

                            <div className="my-auto ">{doubleArrowIcon}</div>

                            <ImageCenter src={select['preview_url']} />

                        </div>
                        <div className="flex flex-col space-y-4">
                            <Title text="Integration Steps" className="text-lg" />
                            <Description text={select['description'] ?? ""} className="font-normal text-sm text-[#586474]" />
                        </div>

                        <div className="space-y-2">
                            <p>step:</p>
                            {
                                select?.connection_step?.map( content => <p className="px-4 text-12px  text-[#586474]" > { content } </p> )
                            }
                        </div>

                        <div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Indegration Section */}
            <div className="w-[70%] h-full overflow-y-auto pb-20 relative">

                { Loading && <LoadingSpinner text='Please Wait...' display={' flex absolute'} /> }

                <div className="w-[90%]  h-[80%]   mx-auto flex flex-col space-y-4">



                    {
                        (ActiveSec == 0) && <>
                            <div className="pt-6 flex flex-col space-y-2">
                                <Title text={`Click the authentication button to link your ${select["mailer"].split('_').join(" ")} account.`} className="font-bold text-base" />
                            </div>

                            {
                                select?.input_credentials?.map(( list ) => {
                                    return (
                                        <InputWithLabel label={ list?.name } onchange={(e)=>ApikeyContentChange( list?.key, e.target.value )} />
                                    )
                                })
                            }

                        </>
                    }

                    {(ActiveSec == 1) && <>
                        <div className="pt-6 flex flex-col space-y-2 ">
                                <Title text={`Choose List`} className="font-bold text-base" />
                            </div>
                            <IntegrationlistEditSec listObj={ListObj} tagObj={TagObj} />
                    </> }


                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}