
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import { Component } from 'react';
import Layout from './pages';
import Auth from './Auth0/';
import _get from 'lodash/get';
import queryString from 'query-string';
import Dashboard from './pages/dashboard';
import Template from './pages/template';
// import RegisterPage from './pages/register/register';
import Login from './pages/login/log';
import Industry from './pages/intro';
import IndustryCategory from './pages/industry/category';
import TemplatePreview from './pages/preview';
import EditTempalte from './pages/edittemplate.';

import { EditerProvider } from './pages/customstate/context';
import { LoginSucsess } from './pages/loginsuccess';
import { Login0 } from './pages/login';

import Main from './pages/main';
import { Logout } from './pages/logout';
import { EditorPreView } from './pages/editerPriview';
import RegisterDEfaultPage from './pages/register';
import { Toaster } from 'react-hot-toast';
import { Emailverification } from './pages/Emailverification';
import { SettingIndex } from './pages/Settings'
import { NotFoundPage } from './pages/404page';
import EditCreateTempalte from './pages/edittemplatecreate';
import CampaignList from './pages/campaign';
import EditGrenaraeAiCreateTempalte from './pages/GEnerateAiTemplate';
import ProjectList from './pages/project';
import InnerProject from './pages/innerProject';
import ResetEmailPage from './pages/resetPasswordEmail';
import ResetEmailVerifyPage from './pages/resetPasswordEmailVerify';
import Sharedproject from './pages/Sharedproject ';
import AppsumoResetEmailPage from './pages/appsumoresetPasswordEmail';
import { InterCom } from './components/intercom';
import { TestBase } from './utils/helpers';
import { PaymentCheckoutPage } from './pages/checkout';
import { PaymentCheckoutPageRedirect } from './pages/checkoutRedirection';
import { OauthCallBackPage } from './pages/OAuthCallabackPage';
import ResendVerification from './pages/resendVerificationEmail';

function App() {

  
  return (
    <>
      <InterCom />
      
      <EditerProvider>
        <Toaster />

        <Router>

          <Routes >

            {/* 404 page */}

            <Route path="/*" name="notfoundpage" element={<NotFoundPage />} />


            {/* Main Route */}

            <Route path='/' name="Industry" element={<Main />} />


            {/* Register And Login Route */}

            <Route path='/register' name="register" element={<RegisterDEfaultPage />} />

            <Route path='/login' name="login" element={<Login0 />} />

            <Route path='/logout' name="logout" element={<Logout />} />


            {/* Autho CallBack Route  or Success Route */}

            <Route path="/login_success" name="LoginSuccess Redirect" element={<LoginSucsess />} exact />


            {/* Email Verify and Reset password */}

            <Route path='/forgot-password' name="reset-email" element={<ResetEmailPage />} />
            
            <Route path='/appsumo/forgot-password/:email/:token' name="reset-email" element={<AppsumoResetEmailPage />} />

            <Route path='/reset/password/:token' name="reset-email-verify" element={<ResetEmailVerifyPage />} />

            <Route path='/email/verification' name="verify-email" element={<ResendVerification />} />

            <Route path='/verify-email/:token' name="verify-email" element={<Emailverification />} />


            {/* Dashboard And settings Route */}

            <Route path='/dashboard' name="dashboard" element={<Dashboard />} />

            <Route path='/settings' name="setting" element={<SettingIndex />} />

            <Route path='/category' name="category" element={<IndustryCategory />} />


            {/* Workspaces And Specific WorkSpace Route */}

            <Route path='/workspace' name="workspace" element={<ProjectList />} />

            <Route path='/workspace/:project_id' name="campaigns" element={<InnerProject />} />
        

            {/* shared-Workspaces And Specific shared-WorkSpace Route */}

            <Route path='/shared-workspace' name="sharedworkspace" element={<Sharedproject />} />

            <Route path='/shared-workspace/:project_id' name="campaigns" element={<InnerProject SharedWS={ true } />} />


            {/* all Campaign Route */}

            <Route path='/campaigns' name="campaigns" element={<CampaignList />} />


            {/* Campaign Edit Temp Route */}

            <Route path='/campaign/edit/:campaign_id' name="edit" element={<EditTempalte />} />

            <Route path='/campaign/edit/:campaign_id/preview' name="edit" element={<EditorPreView />} />


            {/* Campaign Edit Temp Route */}

            <Route path='/shared-workspace/campaign/edit/:project_id/:campaign_id' name="edit" element={<EditTempalte SharedWS={ true } />} />

            <Route path='/shared-workspace/campaign/edit/:project_id/:campaign_id/preview' name="edit" element={<EditorPreView SharedWS={ true } />} />

            
            {/* Teamplate Route */}

            <Route path='/template' name="template" element={<Template />} />

            <Route path='/template-preview/:template_id' name="preview" element={<TemplatePreview />} />


            {/* Teamplate Create Route */}

            <Route path='/template/create/:template_id' name="edit" element={<EditCreateTempalte />} />

            <Route path='/template/create/generate/:template_id' name="edit" element={<EditGrenaraeAiCreateTempalte />} />

            {/* Oauth CallBack Route */}

            <Route path ="/oauth/callback/:mailer" name="oauth" element={<OauthCallBackPage />} />


            {/* Billing */}

            <Route path="/checkout" name='checkout' element={<PaymentCheckoutPage />} />

            <Route path='/checkout/status' name='checkoutstatus' element={<PaymentCheckoutPageRedirect />} />

      
            
            {/* <Route path='/:campaign_id'  name="edit"  element={ <EditTempalte />} /> */}

          </Routes >
        </Router>

      </EditerProvider>
    </>
  );
}

export default App;
