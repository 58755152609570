import React, { useState } from "react";
import { Title } from "../../components/Texts/Title";
import { Description } from "../../components/Texts/Description"
import Input from "../../components/input/input";
import PrimaryButton from "../../components/Button1/primarybutton";
import BorderedButton from "../../components/Button1/BorderedButton";
import Auth from "../../Auth0";
import Link from "../../components/Texts/Link";
// import { RegisterUser, setUserRegisterError } from '../../actions/user';
import DeFaultCheckbox from "../input/deFaultCheckbox";
import { DefaultText } from "../../components/Texts";
import { checkLowercase, checkNumber, checkPasswordLength, checkSpecialCharacters, checkUppercase, clearSession, generateCaptchaToken, getCookie, isValidEmail, loadCaptcha, removeCaptchaContainer, ResetDocTitle, SetDocTitle } from '../../utils';
import { isEmpty } from "lodash";
import { useEffect } from "react";
import axios from "axios";
import { Registeruser } from "../../actions/user";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { REGX } from "../../content/regx";
import { PasswordValid } from "./PasswordValid";


const Form = () => {

    const Navigate = useNavigate()

    const [ loading, setloading ] = useState(false)
    const [passwordTemp, setPassword] = useState("")
    const [isTermsChecked, setTermsCheck] = useState(true)
    const [passwordCheckList, setPasswordCheckList] = useState({
        charNumberValid: false,
        lowercaseValid: false,
        numberValid: false,
        specialCharValid: false,
        uppercaseValid: false
    })


    useEffect(()=>{
        loadCaptcha();
        clearSession();
        SetDocTitle('Register to GoZen Engage - Simplified ')
    })
    

    const getCaptchaToken = (cb) => {     

        try {
            generateCaptchaToken(cb)
        }
        catch (er) {
  

            return false;
        }
    }

    const registerUser = (prop) => {

        setloading(false)

        const email = prop.email.value;
        const first_name = prop.firstname.value;
        const last_name = prop.lastname.value;
        const password = prop.password.value;

        getCaptchaToken(async (token) => {

            if (token) {

                var data = {
                    "email": email,
                    "first_name": first_name,
                    "last_name": last_name,
                    "password": password
                  };

                  Registeruser(data).then (resData => {
                    toast.success(resData?.data);
                    Navigate('/login')
                    
                  } ).catch(err => {
                    
                    toast.error(err?.data)
                  })

                   
            }

        });

        setloading(true)

    }
    const onSubmit = (e) => {
        
        e.preventDefault(e);
       
        let error = {};

        const name= e?.target?.firstname?.value, email=e?.target?.email?.value, password=e?.target?.password?.value
        let isValidPassword = Object.values(passwordCheckList)?.filter((val) => !val).length ? false : true

        
        if (isEmpty(name) || isEmpty(email) || isEmpty(password)) { 
            toast.error("All fields are requried.")
            return false
        } else if (isValidEmail(e.target.email)) { 
            toast.error("Invalid email.")
            return false
        } else if (!isValidPassword) {
            toast.error("Invalid password.")
            return false 
        } else if(isTermsChecked===false){
            toast.error("You need to accept our Terms of Service and Privacy Policy to create a account")
            return false 
        }


        if(isValidEmail(e.target.email)) {error['email'] = 'Please enter valid email'};

        if(!isEmpty(error)) {return error};

        registerUser(e.target);
        setPassword("")

    }

    const loginWithGoogle = () => {
        const webAuth = Auth.getAuth0();
        webAuth.authorize({ connection: 'google-oauth2' }, function (err) {
            if (err) alert(err);
        });
    }

    const onPasswordChange = (val) => { 
        setPassword(val)
        setPasswordCheckList({
            uppercaseValid: REGX.upperCase.test(val) ? true : false,
            lowercaseValid: REGX.lowerCase.test(val) ? true : false,
            charNumberValid: val.length >= 8 ? true : false,
            numberValid: REGX.number.test(val) ? true : false,
            specialCharValid: REGX.specialCharacters.test(val) ? true : false,
        })
    } 

    
    return ( 
        <>
        <section className="w-1/2 flex ">
            <form className="w-9/12 2xl:h-4/5 flex flex-col 2xl:space-y-10 p-11 py-14 2xl:py-18 bg-white rounded-xl my-auto" onSubmit={onSubmit}>
                <div className="flex flex-col 2xl:space-y-4">
                    <Title text ="Get started now!" textSize = "text-xl" fontStyle = "font-bold" margin = "mb-3" />
                    {/* <Description text="Lorem ipsum dolor sit amet consectetur." margin = "mb-3" textSize = "text-md" /> */}
                </div>
                <div className="my-3 flex flex-col">
                    <div className="flex space-x-3">
                        <Input Placeholder={"First Name"} type = "text" name="firstname" id="firstname" width="w-1/2" margin="mb-4" />
                        <Input Placeholder={"Last Name"} type = "text" name="lastname" id="lastname" width="w-1/2" margin="mb-4" />
                    </div>
                    <Input Placeholder={"Email Address"} type = "email" name="email" id="email" margin="mb-4" />
                    <Input Placeholder={"Password"} type = "password" name="password" id="passsword" margin="mb-4" onChange={(e) => onPasswordChange(e.target.value)} />
                    
                    { passwordTemp.length>0 && <PasswordValid {...passwordCheckList} ></PasswordValid> }

                    <div className="flex items-center xl:mb-3 2xl:mb-16px">
                        <DeFaultCheckbox
                        ClassName="cursor-pointer"
                            requiredText="Please accept terms & conditions"
                            isRequired={false}
                            isChecked={isTermsChecked}
                            onChange={()=>{setTermsCheck(o => !o)}}
                            labelComponent={(
                                <span className="flex ml-10px mt-2px items-center flex-wrap ">
                                    <DefaultText text="I agree to the " fontStyle="font-medium" textSize="text-15px" />
                                    <Link fontSize="text-15px" text="Terms of Service" href="https://gozen.io/gozen/terms-and-conditions/" margin="mx-4px" />
                                    <DefaultText text=" and " fontStyle="font-medium" textSize="text-15px" />
                                    <Link fontSize="text-15px" text="Privacy Policy." href="https://gozen.io/growth/privacy-policy/" margin="mx-4px" />
                                </span>
                            )}
                        />
                    </div>
                    <PrimaryButton type="submit" id="submit" text={"Continue"}  icon={
                        loading ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
                    } disabled={ loading } shadow="shadow-sh-prime"  />
                </div>
                <div className="flex flex-row w-3/5 py-2 mx-auto">
                    <hr className="w-3/5 border-1 m-auto" />
                        <span className="my-auto mx-3">or</span>
                    <hr className="w-3/5 border-1 m-auto" />
                </div>
                <div className="flex flex-col mt-3 items-center justify-center">
                    <PrimaryButton text="Sign in with Google" id={"googlesignup"} OnClick ={() => loginWithGoogle()}  backgroundColor="bg-white" textcolor="text-primary"  width="w-full" border="border-2 border-primary" height="h-fit" 
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
                    <image href="https://form-assets.dev.forms.gozen.io/cdn/login/google-logo.png" width="26" height="26" />
                  </svg>
                  }
                    />

{/* 
                    <button type="button" id="googlesignup" class=" outline-none   rounded-8px  text-14px font-medium text-primary
                focus:outline-none   sm:text-sm  bg-white h-fit  w-full   border-2 border-primary py-2 flex justify-center items-center shadow-sm  hover:opacity-90    flex-row   ">
                    <img src="https://form-assets.dev.forms.gozen.io/cdn/login/google-logo.png" alt="Google logo" className="w-[27px] aspect-square" />
                    <span class=" ml-2 text-14px text-primary capitalize">Sign in with Google</span></button> */}
                </div>
                
                <div className="flex mt-24px text-center items-center justify-center">
                    <Description text="Already have an account?" />
                    <Link target="_self" text="Login" href="/login"  margin="ml-4px" />
                </div>
            </form>
        </section>
        </>
    )
}

export default Form ;