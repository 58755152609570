import React from "react";

export const listOfIcon = (icon, width = 20, height = 20, color = "#586474", color2 = 'white') => {

    width = width.toString();

    height = height.toString();

    let list = {

        "edit": (
            <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z" fill={color} />
                <path d="M14.2649 2.61024C12.8099 1.15524 11.3849 1.11774 9.89243 2.61024L8.98493 3.51774C8.90993 3.59274 8.87993 3.71274 8.90993 3.81774C9.47993 5.80524 11.0699 7.39524 13.0574 7.96524C13.0874 7.97274 13.1174 7.98024 13.1474 7.98024C13.2299 7.98024 13.3049 7.95024 13.3649 7.89024L14.2649 6.98274C15.0074 6.24774 15.3674 5.53524 15.3674 4.81524C15.3749 4.07274 15.0149 3.35274 14.2649 2.61024Z" fill={color} />
                <path d="M11.7082 8.6476C11.4907 8.5426 11.2807 8.4376 11.0782 8.3176C10.9132 8.2201 10.7557 8.1151 10.5982 8.0026C10.4707 7.9201 10.3207 7.8001 10.1782 7.6801C10.1632 7.6726 10.1107 7.6276 10.0507 7.5676C9.80323 7.3576 9.52573 7.0876 9.27823 6.7876C9.25573 6.7726 9.21823 6.7201 9.16573 6.6526C9.09073 6.5626 8.96323 6.4126 8.85073 6.2401C8.76073 6.1276 8.65573 5.9626 8.55823 5.7976C8.43823 5.5951 8.33323 5.3926 8.22823 5.1826V5.1826C8.09056 4.88759 7.70337 4.79996 7.47317 5.03015L3.25573 9.2476C3.15823 9.3451 3.06823 9.5326 3.04573 9.6601L2.64073 12.5326C2.56573 13.0426 2.70823 13.5226 3.02323 13.8451C3.29323 14.1076 3.66823 14.2501 4.07323 14.2501C4.16323 14.2501 4.25323 14.2426 4.34323 14.2276L7.22323 13.8226C7.35823 13.8001 7.54573 13.7101 7.63573 13.6126L11.8601 9.38819C12.0857 9.1626 12.001 8.77445 11.7082 8.6476V8.6476Z" fill={color} />
            </svg>),
        "edit2": (
            <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.15597 14.6406C3.69847 14.6406 3.27097 14.4831 2.96347 14.1906C2.57347 13.8231 2.38597 13.2681 2.45347 12.6681L2.73097 10.2381C2.78347 9.78065 3.06097 9.17315 3.38347 8.84315L9.54097 2.32565C11.0785 0.698147 12.6835 0.653147 14.311 2.19065C15.9385 3.72815 15.9835 5.33315 14.446 6.96065L8.28847 13.4781C7.97347 13.8156 7.38847 14.1306 6.93097 14.2056L4.51597 14.6181C4.38847 14.6256 4.27597 14.6406 4.15597 14.6406ZM11.9485 2.18315C11.371 2.18315 10.8685 2.54315 10.3585 3.08315L4.20097 9.60815C4.05097 9.76565 3.87847 10.1406 3.84847 10.3581L3.57097 12.7881C3.54097 13.0356 3.60097 13.2381 3.73597 13.3656C3.87097 13.4931 4.07347 13.5381 4.32097 13.5006L6.73597 13.0881C6.95347 13.0506 7.31347 12.8556 7.46347 12.6981L13.621 6.18065C14.551 5.19065 14.8885 4.27565 13.531 3.00065C12.931 2.42315 12.4135 2.18315 11.9485 2.18315Z" fill={color} />
                <path d="M13.0059 8.21263C12.9909 8.21263 12.9684 8.21263 12.9534 8.21263C10.6134 7.98013 8.73094 6.20263 8.37094 3.87763C8.32594 3.57013 8.53594 3.28513 8.84344 3.23263C9.15094 3.18763 9.43594 3.39763 9.48844 3.70513C9.77344 5.52013 11.2434 6.91513 13.0734 7.09513C13.3809 7.12513 13.6059 7.40263 13.5759 7.71013C13.5384 7.99513 13.2909 8.21263 13.0059 8.21263Z" fill={color} />
                <path d="M15.751 17.0625H2.25098C1.94348 17.0625 1.68848 16.8075 1.68848 16.5C1.68848 16.1925 1.94348 15.9375 2.25098 15.9375H15.751C16.0585 15.9375 16.3135 16.1925 16.3135 16.5C16.3135 16.8075 16.0585 17.0625 15.751 17.0625Z" fill={color} />
            </svg>),
        "uparrow": (<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5523 7.74008C13.4098 7.74008 13.2673 7.68758 13.1548 7.57508L8.9998 3.42008L4.84484 7.57508C4.62734 7.79258 4.26734 7.79258 4.04984 7.57508C3.83234 7.35758 3.83234 6.99758 4.04984 6.78008L8.6023 2.22758C8.8198 2.01008 9.1798 2.01008 9.39729 2.22758L13.9498 6.78008C14.1673 6.99758 14.1673 7.35758 13.9498 7.57508C13.8448 7.68758 13.6948 7.74008 13.5523 7.74008Z" fill={color} />
            <path d="M9 15.9379C8.6925 15.9379 8.4375 15.6829 8.4375 15.3754V2.75293C8.4375 2.44543 8.6925 2.19043 9 2.19043C9.30749 2.19043 9.56249 2.44543 9.56249 2.75293V15.3754C9.56249 15.6829 9.30749 15.9379 9 15.9379Z" fill={color} />
        </svg>
        ),
        'uparrow2': (
            <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.76693 0.741553L5.00026 3.97489L8.23359 0.741553C8.55859 0.416553 9.0836 0.416553 9.4086 0.741553C9.7336 1.06655 9.7336 1.59155 9.4086 1.91655L5.58359 5.74155C5.25859 6.06655 4.73359 6.06655 4.40859 5.74155L0.583594 1.91655C0.258594 1.59155 0.258594 1.06655 0.583594 0.741553C0.908594 0.424886 1.44193 0.416553 1.76693 0.741553Z" fill={color} />
            </svg>
        ),
        'leftarrow': (
            <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.58251 10.9782C5.69335 10.9782 5.80418 10.9373 5.89168 10.8498C6.06085 10.6807 6.06085 10.4007 5.89168 10.2315L2.66001 6.99982L5.89168 3.76816C6.06085 3.59899 6.06085 3.31899 5.89168 3.14982C5.72251 2.98066 5.44251 2.98066 5.27335 3.14982L1.73251 6.69066C1.56335 6.85982 1.56335 7.13982 1.73251 7.30899L5.27335 10.8498C5.36085 10.9373 5.47168 10.9782 5.58251 10.9782Z" fill={color} />
                <path d="M2.141 7.4375H11.9585C12.1977 7.4375 12.396 7.23917 12.396 7C12.396 6.76083 12.1977 6.5625 11.9585 6.5625H2.141C1.90183 6.5625 1.7035 6.76083 1.7035 7C1.7035 7.23917 1.90183 7.4375 2.141 7.4375Z" fill={color} />
            </svg>
        ),
        "downarrow": (<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.9998 15.9373C8.8573 15.9373 8.7148 15.8848 8.6023 15.7723L4.04984 11.2198C3.83234 11.0023 3.83234 10.6423 4.04984 10.4248C4.26734 10.2073 4.62734 10.2073 4.84484 10.4248L8.9998 14.5798L13.1548 10.4248C13.3723 10.2073 13.7323 10.2073 13.9498 10.4248C14.1673 10.6423 14.1673 11.0023 13.9498 11.2198L9.39729 15.7723C9.2848 15.8848 9.1423 15.9373 8.9998 15.9373Z" fill={color} />
            <path d="M9 15.81C8.6925 15.81 8.4375 15.555 8.4375 15.2475V2.625C8.4375 2.3175 8.6925 2.0625 9 2.0625C9.30749 2.0625 9.56249 2.3175 9.56249 2.625V15.2475C9.56249 15.555 9.30749 15.81 9 15.81Z" fill={color} />
        </svg>),
        'downarrow2': (
            <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.75498 5.03961C2.85347 5.03948 2.95101 5.05885 3.04198 5.09661C3.13295 5.13437 3.21553 5.18977 3.28498 5.25961L7.12148 9.09561C7.23756 9.21172 7.37537 9.30382 7.52705 9.36666C7.67873 9.4295 7.8413 9.46184 8.00548 9.46184C8.16966 9.46184 8.33223 9.4295 8.48391 9.36666C8.63559 9.30382 8.7734 9.21172 8.88948 9.09561L12.72 5.26461C12.7892 5.19298 12.8719 5.13584 12.9634 5.09653C13.0549 5.05723 13.1533 5.03654 13.2529 5.03567C13.3525 5.03481 13.4513 5.05378 13.5434 5.09149C13.6356 5.1292 13.7194 5.18489 13.7898 5.25531C13.8602 5.32573 13.9159 5.40947 13.9536 5.50164C13.9913 5.59382 14.0103 5.69258 14.0094 5.79216C14.0086 5.89175 13.9879 5.99016 13.9486 6.08166C13.9092 6.17317 13.8521 6.25593 13.7805 6.32511L9.94998 10.1561C9.43393 10.6712 8.7346 10.9605 8.00548 10.9605C7.27636 10.9605 6.57702 10.6712 6.06098 10.1561L2.22448 6.32011C2.11952 6.21522 2.04804 6.08155 2.01907 5.93602C1.9901 5.79049 2.00495 5.63964 2.06175 5.50255C2.11854 5.36547 2.21472 5.24831 2.33812 5.1659C2.46152 5.08349 2.60659 5.03954 2.75498 5.03961Z" fill={color} />
            </svg>),
        "delete": (<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7506 5.04785C15.7356 5.04785 15.7131 5.04785 15.6906 5.04785C11.7231 4.65035 7.76318 4.50035 3.84071 4.89785L2.31072 5.04785C1.99573 5.07785 1.71823 4.85285 1.68823 4.53785C1.65823 4.22285 1.88323 3.95285 2.19073 3.92285L3.72071 3.77285C7.71068 3.36785 11.7531 3.52535 15.8031 3.92285C16.1106 3.95285 16.3356 4.23035 16.3056 4.53785C16.2831 4.83035 16.0356 5.04785 15.7506 5.04785Z" fill={color} />
            <path d="M6.37555 4.29C6.34555 4.29 6.31555 4.29 6.27805 4.2825C5.97805 4.23 5.76806 3.9375 5.82056 3.6375L5.98555 2.655C6.10555 1.935 6.27055 0.9375 8.01804 0.9375H9.98302C11.738 0.9375 11.903 1.9725 12.0155 2.6625L12.1805 3.6375C12.233 3.945 12.023 4.2375 11.723 4.2825C11.4155 4.335 11.123 4.125 11.078 3.825L10.913 2.85C10.808 2.1975 10.7855 2.07 9.99052 2.07H8.02554C7.23054 2.07 7.21554 2.175 7.10305 2.8425L6.93055 3.8175C6.88555 4.095 6.64555 4.29 6.37555 4.29Z" fill={color} />
            <path d="M11.4077 17.0627H6.59276C3.97528 17.0627 3.87029 15.6152 3.78779 14.4452L3.30029 6.89268C3.27779 6.58518 3.51779 6.31518 3.82529 6.29268C4.14028 6.27768 4.40278 6.51018 4.42528 6.81768L4.91278 14.3702C4.99528 15.5102 5.02527 15.9377 6.59276 15.9377H11.4077C12.9827 15.9377 13.0127 15.5102 13.0877 14.3702L13.5752 6.81768C13.5977 6.51018 13.8677 6.27768 14.1752 6.29268C14.4827 6.31518 14.7227 6.57768 14.7002 6.89268L14.2127 14.4452C14.1302 15.6152 14.0252 17.0627 11.4077 17.0627Z" fill={color} />
            <path d="M10.2455 12.9375H7.74804C7.44054 12.9375 7.18555 12.6825 7.18555 12.375C7.18555 12.0675 7.44054 11.8125 7.74804 11.8125H10.2455C10.553 11.8125 10.808 12.0675 10.808 12.375C10.808 12.6825 10.553 12.9375 10.2455 12.9375Z" fill={color} />
            <path d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z" fill={color} />
        </svg>),
        "delete2": (
            <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1376_9229)">
                    <path d="M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25H4.5V14.25ZM6.8775 9.4425C6.585 9.15 6.585 8.6775 6.8775 8.385C7.17 8.0925 7.6425 8.0925 7.935 8.385L9 9.4425L10.0575 8.385C10.35 8.0925 10.8225 8.0925 11.115 8.385C11.4075 8.6775 11.4075 9.15 11.115 9.4425L10.0575 10.5L11.115 11.5575C11.4075 11.85 11.4075 12.3225 11.115 12.615C10.8225 12.9075 10.35 12.9075 10.0575 12.615L9 11.5575L7.9425 12.615C7.65 12.9075 7.1775 12.9075 6.885 12.615C6.5925 12.3225 6.5925 11.85 6.885 11.5575L7.9425 10.5L6.8775 9.4425ZM13.5 3H11.625L11.0925 2.4675C10.9575 2.3325 10.7625 2.25 10.5675 2.25H7.4325C7.2375 2.25 7.0425 2.3325 6.9075 2.4675L6.375 3H4.5C4.0875 3 3.75 3.3375 3.75 3.75C3.75 4.1625 4.0875 4.5 4.5 4.5H13.5C13.9125 4.5 14.25 4.1625 14.25 3.75C14.25 3.3375 13.9125 3 13.5 3Z" fill={color} />
                </g>
                <defs>
                    <clipPath id="clip0_1376_9229">
                        <rect width={width} height={height} fill={color2} />
                    </clipPath>
                </defs>
            </svg>
        ),
        "close": (
            <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.825 6.18562C19.4025 5.76312 18.72 5.76312 18.2975 6.18562L13 11.4723L7.70253 6.17479C7.28003 5.75229 6.59753 5.75229 6.17503 6.17479C5.75253 6.59729 5.75253 7.27979 6.17503 7.70229L11.4725 12.9998L6.17503 18.2973C5.75253 18.7198 5.75253 19.4023 6.17503 19.8248C6.59753 20.2473 7.28003 20.2473 7.70253 19.8248L13 14.5273L18.2975 19.8248C18.72 20.2473 19.4025 20.2473 19.825 19.8248C20.2475 19.4023 20.2475 18.7198 19.825 18.2973L14.5275 12.9998L19.825 7.70229C20.2367 7.29062 20.2367 6.59729 19.825 6.18562Z" fill={color} />
            </svg>
        ),
        "img": (
            <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 1.75V12.25H1.75V1.75H12.25ZM12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM8.605 6.895L6.355 9.7975L4.75 7.855L2.5 10.75H11.5L8.605 6.895Z" fill={color} />
            </svg>
        ),
        'copyclip': (
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3337 10.75V14.25C13.3337 17.1666 12.167 18.3333 9.25033 18.3333H5.75033C2.83366 18.3333 1.66699 17.1666 1.66699 14.25V10.75C1.66699 7.83329 2.83366 6.66663 5.75033 6.66663H9.25033C12.167 6.66663 13.3337 7.83329 13.3337 10.75Z" fill={color} />
                <path d="M14.2498 1.66663H10.7498C8.24635 1.66663 7.03822 2.53228 6.74575 4.61571C6.66834 5.16721 7.1298 5.62496 7.68671 5.62496H9.2498C12.7498 5.62496 14.3748 7.24996 14.3748 10.75V12.3131C14.3748 12.87 14.8326 13.3314 15.3841 13.254C17.4675 12.9615 18.3331 11.7534 18.3331 9.24996V5.74996C18.3331 2.83329 17.1665 1.66663 14.2498 1.66663Z" fill={color} />
            </svg>),
        "connect": (
            <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0388 11.9628L8.20388 13.7976C7.67115 14.3161 6.9557 14.604 6.21232 14.599C5.46894 14.5941 4.75741 14.2966 4.23168 13.771C3.70595 13.2454 3.40828 12.534 3.40309 11.7906C3.3979 11.0472 3.6856 10.3317 4.20394 9.79882L6.03882 7.96225C6.14429 7.85671 6.20352 7.71358 6.20346 7.56437C6.20341 7.41515 6.14408 7.27207 6.03854 7.1666C5.93299 7.06113 5.78986 7.0019 5.64065 7.00195C5.49144 7.00201 5.34835 7.06133 5.24288 7.16688L3.40857 9.00344C2.66735 9.74504 2.25108 10.7507 2.25134 11.7992C2.25161 12.8477 2.66838 13.8532 3.40997 14.5944C4.15157 15.3356 5.15724 15.7519 6.20575 15.7516C7.25426 15.7514 8.25972 15.3346 9.00094 14.593L10.8358 12.7581C10.9383 12.652 10.995 12.51 10.9937 12.3625C10.9924 12.215 10.9333 12.0739 10.829 11.9696C10.7247 11.8653 10.5836 11.8062 10.4361 11.8049C10.2886 11.8036 10.1465 11.8603 10.0404 11.9628H10.0388Z" fill={color} />
                <path d="M14.5945 3.40933C14.2285 3.04078 13.7929 2.74855 13.3132 2.54954C12.8334 2.35054 12.3189 2.24873 11.7994 2.25001C11.2803 2.24863 10.766 2.35018 10.2863 2.54879C9.80669 2.74739 9.37116 3.03911 9.00493 3.40708L7.16725 5.24251C7.0617 5.34799 7.00237 5.49107 7.00232 5.64028C7.00227 5.7895 7.06149 5.93262 7.16696 6.03817C7.27244 6.14372 7.41552 6.20304 7.56473 6.2031C7.71395 6.20315 7.85707 6.14393 7.96262 6.03845L9.79918 4.20358C10.0611 3.94013 10.3726 3.73125 10.7157 3.58902C11.0589 3.4468 11.4269 3.37406 11.7983 3.37501C12.3576 3.3752 12.9042 3.54119 13.3692 3.85201C13.8341 4.16283 14.1965 4.60452 14.4105 5.12125C14.6244 5.63798 14.6804 6.20654 14.5713 6.75506C14.4622 7.30359 14.1929 7.80745 13.7974 8.20295L11.9626 10.0378C11.857 10.1434 11.7977 10.2865 11.7977 10.4358C11.7977 10.5851 11.857 10.7282 11.9626 10.8338C12.0681 10.9393 12.2113 10.9986 12.3605 10.9986C12.5098 10.9986 12.6529 10.9393 12.7585 10.8338L14.5934 9.00001C15.3336 8.25813 15.7495 7.25295 15.7497 6.2049C15.7499 5.15686 15.3345 4.15151 14.5945 3.40933Z" fill={color} />
                <path d="M10.2907 6.91571L6.91574 10.2907C6.86202 10.3426 6.81917 10.4047 6.78969 10.4733C6.76021 10.5419 6.74469 10.6157 6.74404 10.6904C6.74339 10.7651 6.75762 10.8392 6.78591 10.9083C6.81419 10.9774 6.85596 11.0402 6.90877 11.0931C6.96159 11.1459 7.02439 11.1876 7.09352 11.2159C7.16265 11.2442 7.23672 11.2584 7.31141 11.2578C7.38609 11.2571 7.45991 11.2416 7.52853 11.2121C7.59716 11.1827 7.65923 11.1398 7.71112 11.0861L11.0861 7.71109C11.1886 7.605 11.2453 7.46291 11.244 7.31542C11.2427 7.16794 11.1836 7.02686 11.0793 6.92256C10.975 6.81827 10.8339 6.75912 10.6864 6.75783C10.5389 6.75655 10.3968 6.81325 10.2907 6.91571Z" fill={color} />
            </svg>
        ),
        'disconnect': (
            <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.64837 7.74689C4.86831 7.52808 5.22381 7.52864 5.44375 7.74802C5.66368 7.96795 5.66256 8.32402 5.44262 8.54395L4.20681 9.77527C3.67131 10.3108 3.376 11.0229 3.376 11.78C3.376 12.5371 3.67075 13.2493 4.20681 13.7848C4.744 14.3225 5.45725 14.6206 6.21437 14.6246C6.96531 14.6246 7.68587 14.3332 8.21575 13.8045L9.45775 12.5585C9.67712 12.3386 10.0326 12.3375 10.2531 12.5574C10.4731 12.7768 10.4742 13.1328 10.2542 13.3533L9.01168 14.5998C8.26918 15.3423 7.26062 15.7501 6.20875 15.7501C5.15293 15.7445 4.15956 15.3288 3.41143 14.5807C2.66275 13.8326 2.251 12.8381 2.251 11.7806C2.251 10.7225 2.66275 9.72802 3.41143 8.97933L4.64837 7.74689ZM7.04856 10.1566L6.598 10.6072C6.37806 10.8271 6.37806 11.1826 6.598 11.4026C6.70768 11.5123 6.85168 11.5674 6.99568 11.5674C7.13968 11.5674 7.28368 11.5123 7.39337 11.4026L7.84393 10.952C8.06387 10.7321 8.06387 10.3766 7.84393 10.1566C7.624 9.9367 7.2685 9.9367 7.04856 10.1566ZM2.41581 2.41495C2.63575 2.19502 2.99125 2.19502 3.21118 2.41495L6.59687 5.80064L6.59856 5.79783L8.99031 3.41002C9.739 2.66189 10.7329 2.25014 11.791 2.25014C12.8491 2.25014 13.843 2.66189 14.5911 3.41002C15.3392 4.15814 15.751 5.15264 15.751 6.2107C15.751 7.26877 15.3392 8.2627 14.5911 9.01083L12.2033 11.4031L12.2005 11.4048L15.5862 14.7905C15.8061 15.0105 15.8061 15.366 15.5862 15.5859C15.4765 15.6956 15.3325 15.7507 15.1885 15.7507C15.0445 15.7507 14.9005 15.6956 14.7908 15.5859L2.41581 3.21033C2.19587 2.99039 2.19587 2.63489 2.41581 2.41495ZM9.00043 8.2042L10.6086 6.59602C10.8286 6.37608 11.1841 6.37608 11.404 6.59602C11.6239 6.81595 11.6239 7.17202 11.404 7.39139L9.79581 8.99958L11.4057 10.6095L11.4068 10.6078L13.7952 8.21545C14.3307 7.67995 14.626 6.96783 14.626 6.2107C14.626 5.45358 14.3312 4.74145 13.7952 4.20595C13.2591 3.67045 12.5476 3.3757 11.791 3.3757C11.0344 3.3757 10.3217 3.67045 9.78568 4.20652L7.39337 6.59489L7.39168 6.59602L9.00043 8.20477V8.2042Z" fill={color} />
            </svg>
        ),
        'doubleArrow': (
            <svg width={width} height={height} viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.95339 9.29599L0.899219 13.3647C0.622135 13.6564 0.622135 14.1085 0.899219 14.4002L4.95339 18.4689C5.40547 18.9356 6.19297 18.6002 6.19297 17.9585V15.3335H14.9576C15.7596 15.3335 16.4159 14.6772 16.4159 13.8752C16.4159 13.0731 15.7596 12.4168 14.9576 12.4168H6.19297V9.80641C6.19297 9.15016 5.40547 8.82932 4.95339 9.29599ZM26.1138 4.61474L22.0596 0.545992C21.6076 0.0793255 20.8201 0.414742 20.8201 1.05641V3.66683H12.0409C11.2388 3.66683 10.5826 4.32307 10.5826 5.12516C10.5826 5.92724 11.2388 6.58349 12.0409 6.58349H20.8055V9.19391C20.8055 9.85016 21.593 10.171 22.0451 9.70432L26.0992 5.63558C26.3909 5.35849 26.3909 4.89182 26.1138 4.61474Z" fill={color} />
            </svg>
        ),
        'multiSelect': (
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0001 19.26H10.9301C10.4801 19.26 10.1101 18.89 10.1101 18.44C10.1101 17.99 10.4801 17.62 10.9301 17.62H20.0001C20.4501 17.62 20.8201 17.99 20.8201 18.44C20.8201 18.9 20.4501 19.26 20.0001 19.26Z" fill={color} />
                <path d="M20.0001 12.97H10.9301C10.4801 12.97 10.1101 12.6 10.1101 12.15C10.1101 11.7 10.4801 11.33 10.9301 11.33H20.0001C20.4501 11.33 20.8201 11.7 20.8201 12.15C20.8201 12.6 20.4501 12.97 20.0001 12.97Z" fill={color} />
                <path d="M20.0001 6.67H10.9301C10.4801 6.67 10.1101 6.3 10.1101 5.85C10.1101 5.4 10.4801 5.03 10.9301 5.03H20.0001C20.4501 5.03 20.8201 5.4 20.8201 5.85C20.8201 6.3 20.4501 6.67 20.0001 6.67Z" fill={color} />
                <path d="M4.90993 8.03001C4.68993 8.03001 4.47993 7.94001 4.32993 7.79001L3.41993 6.88001C3.09993 6.56001 3.09993 6.04001 3.41993 5.72001C3.73993 5.40001 4.25993 5.40001 4.57993 5.72001L4.90993 6.05001L7.04993 3.91001C7.36993 3.59001 7.88993 3.59001 8.20993 3.91001C8.52993 4.23001 8.52993 4.75001 8.20993 5.07001L5.48993 7.79001C5.32993 7.94001 5.12993 8.03001 4.90993 8.03001Z" fill={color} />
                <path d="M4.90993 14.33C4.69993 14.33 4.48993 14.25 4.32993 14.09L3.41993 13.18C3.09993 12.86 3.09993 12.34 3.41993 12.02C3.73993 11.7 4.25993 11.7 4.57993 12.02L4.90993 12.35L7.04993 10.21C7.36993 9.89 7.88993 9.89 8.20993 10.21C8.52993 10.53 8.52993 11.05 8.20993 11.37L5.48993 14.09C5.32993 14.25 5.11993 14.33 4.90993 14.33Z" fill={color} />
                <path d="M4.90993 20.33C4.69993 20.33 4.48993 20.25 4.32993 20.09L3.41993 19.18C3.09993 18.86 3.09993 18.34 3.41993 18.02C3.73993 17.7 4.25993 17.7 4.57993 18.02L4.90993 18.35L7.04993 16.21C7.36993 15.89 7.88993 15.89 8.20993 16.21C8.52993 16.53 8.52993 17.05 8.20993 17.37L5.48993 20.09C5.32993 20.25 5.11993 20.33 4.90993 20.33Z" fill={color} />
            </svg>
        ),
        'desktop': (
            <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 1.5H2.25C1.425 1.5 0.75 2.175 0.75 3V12C0.75 12.825 1.425 13.5 2.25 13.5H7.5V15H6.75C6.3375 15 6 15.3375 6 15.75C6 16.1625 6.3375 16.5 6.75 16.5H11.25C11.6625 16.5 12 16.1625 12 15.75C12 15.3375 11.6625 15 11.25 15H10.5V13.5H15.75C16.575 13.5 17.25 12.825 17.25 12V3C17.25 2.175 16.575 1.5 15.75 1.5ZM15 12H3C2.5875 12 2.25 11.6625 2.25 11.25V3.75C2.25 3.3375 2.5875 3 3 3H15C15.4125 3 15.75 3.3375 15.75 3.75V11.25C15.75 11.6625 15.4125 12 15 12Z" fill={color} />
            </svg>
        ),
        'mobile': (<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0.75H6C4.755 0.75 3.75 1.755 3.75 3V15C3.75 16.245 4.755 17.25 6 17.25H12C13.245 17.25 14.25 16.245 14.25 15V3C14.25 1.755 13.245 0.75 12 0.75ZM10.125 15.75H7.875C7.665 15.75 7.5 15.585 7.5 15.375C7.5 15.165 7.665 15 7.875 15H10.125C10.335 15 10.5 15.165 10.5 15.375C10.5 15.585 10.335 15.75 10.125 15.75ZM12.75 13.5H5.25V3H12.75V13.5Z" fill={color} />
        </svg>),
        'tablet': (<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.875 0H3.375C2.34 0 1.5 0.84 1.5 1.875V16.125C1.5 17.16 2.34 18 3.375 18H13.875C14.91 18 15.75 17.16 15.75 16.125V1.875C15.75 0.84 14.91 0 13.875 0ZM8.625 17.25C8.0025 17.25 7.5 16.7475 7.5 16.125C7.5 15.5025 8.0025 15 8.625 15C9.2475 15 9.75 15.5025 9.75 16.125C9.75 16.7475 9.2475 17.25 8.625 17.25ZM14.25 14.25H3V2.25H14.25V14.25Z" fill={color} />
        </svg>),
        'emptycircle': (
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill={color} />
            </svg>),
        'selectedcirlefill': (
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill={color} />
            </svg>
        )

    }

    return list[icon];

}
