import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import { isEmpty, isNotEmpty } from "../../../utils/helpers";
import { CalculaterResult } from "../../../utils/Calculater";
import toast from 'react-hot-toast'

export const CalculatorResultBlock = ({ pageContent = {}, Type = "calculater", val = [], Formula , Question }) => {

    const [Message, setMessage] = useState("")

    const [SumVAlue, setsumValue] = useState(0)

    const [Isvaild, setIsvaild] = useState(0)
    console.log({Formula,Question,val})

    const Calculation = () => {

        try {

            if (Type === "calculater") {

                const CalculaterData = new CalculaterResult({ question: Question });

                const IsValidINput = CalculaterData.userInputFormula(String(Formula).toLowerCase(), val, true);

                console.log('IsValidINput',IsValidINput)

                setIsvaild(IsValidINput.status)

                setMessage(IsValidINput.message)

                setsumValue(eval(IsValidINput.sample))

            }

        } catch (err) {

            toast.error("Invalid Input!")

            setIsvaild(false)

            setMessage("Invalid Input!")

            setsumValue(0)
        }

    }

    useEffect(() => { Calculation() }, [val])

    return (

        <div className="w-full justify-center flex">

            {
                pageContent?.Calresults?.map((CalDAta, index) => {


                    let FinalResult = (isEmpty(SumVAlue) || isNaN(SumVAlue)) ? 0 : SumVAlue;

                    let FinalResultStr = ``;


                    if (CalDAta?.finalResult) {


                        const SplitData = CalDAta?.finalResult?.split(`{Final_Calculation}`);

                        if (SplitData?.length == 0) FinalResultStr += FinalResult;

                        else {

                            SplitData?.map((listobj, ind) => {

                                if (((ind + 1) % 2) == 0) {

                                    let temp = FinalResult + `${listobj}`;

                                    FinalResultStr += temp;
                                }

                                else FinalResultStr = `${listobj}`;

                            })
                        }


                    }

                    else FinalResultStr += FinalResult;

                    return (

                        <div className="w-[30%]  min-w-[180px]  space-y-3 rounded-xl p-4 bg-white mx-auto">

                            <div className="h-48 flex rounded-xl border-1 max-h-48">

                                <div className="flex m-auto max-h-full">

                                    <img src={CalDAta?.imagesrc !== "" ? CalDAta?.imagesrc : "/asset/images/emptyimage.png"} alt="productimage" className="flex m-auto max-h-48" />

                                </div>

                                {/* <img src={list?.productImageSrc} alt="" /> */}

                            </div>

                            <p className="text-black text-base font-medium">{CalDAta?.title !== "" ? CalDAta?.title : "Title"}</p>

                            <p className="text-black text-sm">{CalDAta?.description !== "" ? CalDAta?.description : "Description"}</p>

                            {/* <p className="text-black text-lg font-bold overflow-hidden truncate">{CalDAta?.finalResult}{(isEmpty(SumVAlue) || isNaN(SumVAlue)) ? 0 : SumVAlue}</p> */}

                            <p className="text-black text-lg font-bold overflow-hidden truncate">{FinalResultStr}</p>

                            <PrimaryButton text={CalDAta?.button !== "" ? CalDAta?.button : "vist"} OnClick={() => { if (CalDAta?.buttonlink !== "") Navigator(CalDAta?.buttonLink) }} />

                        </div>
                    )
                })
            }


        </div>
    )

}