import React, { useEffect, useState } from "react";
import DefaultDropMenu from "../input/droplist";
import PrimaryButton from "../Button1/primarybutton";
import { IntegrationTagEditor } from "./IntrgtationTAgEdit";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";
import { listOfIcon } from "../../content/iconList";


export const IntegrationlistEditSec = ({
    listObj=[],
    tagObj =[]
}) => {

    const Dispatch = useDispatch()

    const [ TriggerEditorSec, SetTriggerEditor ] = useState(false);

    const [ selectedList, setSelectedList ] = useState({});

    const { fields,mailer, orderslist, integrationOnj } = useSelector((state => ({ fields: state?.fields,orderslist:state?.fieldsOrder,mailer:state?.integrationMailerObj,integrationOnj:state?.Campaign_integration })))

    let listname = mailer?.list.map(list => list?.name) ?? [];

    const tagname = mailer?.field?.map(list => list?.name) ?? [];

    const WorkspaceName = mailer?.workspace?.map(list => list?.name) ?? [];

    const [ selectWorkspace, setSelectWorkspace ] = useState( mailer?.workspace?.[0]?.workspace_id ?? "");

    const [ ListwsObj, setlistObj ] = useState( mailer?.list ?? [] )

    const [ active, setactive ] = useState( 0 )

    useEffect( () => {

        if( mailer?.workspace ) {

            let data = mailer?.list.filter(list => { 

                if( list?.workspace_id == selectWorkspace ) return list

            } )

            setlistObj( data )

        }

    },[ selectWorkspace ] )


    const getIntegrationObj = integrationOnj.find(list => list?.mailer == mailer?.mailer);

    const FiledType = orderslist?.map( list => fields[list]?.filedContent?.filedName || fields[list]?.type) ;


    const TriggerTag = (indVal , list_id, list_obj) => {

        SetTriggerEditor(true);

        setSelectedList( list_obj );

    }

    const selectIntegrationlist = ( index, list_id, filedId, name, list_obj, actionType = 'add'  ) => {

        Dispatch( Actions?.onCampaignIntegrationListCC({  mailer:mailer?.mailer, list_id, filedId, name, list_obj, actionType }) )

    }

    const DelIcon = (color = '#586474') => listOfIcon('delete2',18,18,color,'white')

    return (

        <>

            {TriggerEditorSec && <IntegrationTagEditor setTargetSetting={SetTriggerEditor} mailer={mailer?.mailer} fieldsObj={mailer?.field} list_obj={ selectedList } selectedList={selectedList?.list_id} />}

            <div className="flex flex-col space-y-4  ">

                { (mailer?.workspace) && <div>
                    
                    <DefaultDropMenu SelectIndex={true} list={WorkspaceName} active={active} label="Choose workspace" onchange={( ind ) => { setSelectWorkspace( mailer?.workspace[ind].workspace_id??"" );setactive(ind)}} />

                </div>}


                {
                    ListwsObj?.map((list,index) => {

                        const selecedObj = getIntegrationObj?.integration_list[list?.list_id]?.selectedFields ;


                        return (

                            <div className="flex flex-col space-y-2" >

                                <div className="flex space-x-3" >

                                    <DefaultDropMenu label={`Select your template you want to integrate with ${mailer?.mailer}'s "${list?.name}" list.`} list={FiledType} spaceY="space-y-4" 
                                    SelectIndex={true}
                                    onchange={ (ind) => selectIntegrationlist( ind, list?.list_id, orderslist[ind],FiledType[ind],list, 'add'  ) }
                                    />

                                    <PrimaryButton text={"Edit field"} margin="mt-10" OnClick={() => TriggerTag( index, list?.list_id, list )} />

                                </div>

                                <div className="flex flex-wrap space-x-2">

                                    {
                                        selecedObj?.map(listTag => {
                                            return (

                                                <p className="flex space-x-2 text-gray-500 w-fit px-3 py-2 rounded-md border-gray-300 bg-gray-300">

                                                    <span className="flex my-auto" >{fields[listTag]?.filedContent?.filedName || fields[listTag]?.type}</span>

                                                    <span className="flex my-auto cursor-pointer" onClick={() =>selectIntegrationlist( 1, list?.list_id, listTag ,(fields[listTag]?.filedContent?.filedName || fields[listTag]?.type),list, 'remove'  )} >{DelIcon('gray')}</span>

                                                </p>
                                            )
                                        })
                                    }


                                </div>

                            </div>
                        )
                    })
                }



            </div>
        </>
    )
}